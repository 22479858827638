<template>
    <div>
        <v-row>
            <v-col>
                <v-btn v-if="$parent.$parent.get_permission('edit')" class="ma-1" large solo depressed color="secondary" :to="{ name: 'dashboard_liderazgos_create' }">
                    <v-icon class="mr-2">assignment</v-icon>
                    <span class="subtitle-2">Añadir Compromiso de seguridad</span>
                </v-btn>
                <v-btn v-if="$parent.$parent.get_permission('export')" class="ma-1" large solo depressed color="secondary" @click="$refs.exportar_dialog.show()">
                    <v-icon class="mr-2">file_copy</v-icon>
                    <span class="subtitle-2">Exportar Tabla</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="$parent.$parent.get_permission('show_resumen')">
                <LVRendimiento :items="tabla_items" />
            </v-col>
            <v-col cols="12">
                <DashCard title="Compromisos seguridad" body-class="pa-0">
                    <BuscadorTabla ref="buscador" class="px-4 pt-4" v-model="busqueda" :target="tabla_items" :types="busqueda_settings" :estados="estados" />
                    <v-divider />
                    <v-skeleton-loader type="table" :loading="tabla_loading">
                        <v-data-table dense :headers="headers" :items="tabla_items" :search="busqueda" :custom-filter="filtro" :mobile-breakpoint="0">
                            <template v-slot:item.cod_sapehs="{ item }">
                                <router-link :to="{ name: 'dashboard_liderazgos_item', params: { id: item.id } }">{{ item.cod_sapehs }}</router-link>
                            </template>
                            <template v-slot:item.fecha_ejecucion="{ item }">
                                {{ item.fecha_ejecucion | moment("L") }}
                            </template>
                            <template v-slot:item.coordinador_inicial="{ item }">
                                <v-chip dark color="indigo" v-if="!!item.coordinador_inicial">
                                    <v-icon left>account_circle</v-icon>
                                    {{ item.coordinador_inicial.first_name }} {{ item.coordinador_inicial.last_name }}
                                </v-chip>
                                <template v-else>
                                    No asignado
                                </template>
                            </template>
                            <template v-slot:item.estado_tiempo="{ item }">
                                <v-chip dark :color="item.estado_tiempo | color_estado('tiempo')">
                                    {{ item.estado_tiempo | estado("tiempo") }}
                                </v-chip>
                            </template>
                            <template v-slot:item.estado_actividad="{ item }">
                                <v-chip dark :color="item.estado_actividad | color_estado('liderazgo')">
                                    {{ item.estado_actividad |  estado("liderazgo") }}
                                </v-chip>
                            </template>
                            <template v-slot:item.actividad="{ item }">
                                <span :title="item.actividad">{{ item.actividad | see_more(80) }}</span>
                            </template>
                            <template v-slot:item.acciones="{ item }">
                                <v-menu offset-y v-if="$parent.$parent.get_permission('read') || $parent.$parent.get_permission('edit') || $parent.$parent.get_permission('delete')">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text fab small color="primary" depressed v-on="on">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list :dense="$vuetify.breakpoint.mdAndUp">
                                        <v-list-item v-if="$parent.$parent.get_permission('read')" :to="{ name: 'dashboard_liderazgos_item', params: { id: item.id } }">
                                            <v-list-item-icon>
                                                <v-icon>visibility</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Ver</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="$parent.$parent.get_permission('edit')" :to="{ name: 'dashboard_liderazgos_edit', params: { id: item.id } }">
                                            <v-list-item-icon>
                                                <v-icon>edit</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="$parent.$parent.get_permission('edit') && $parent.$parent.get_permission('delete')" @click="ask_eliminar(item)">
                                            <v-list-item-icon>
                                                <v-icon>delete</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
        </v-row>
        <DialogExportarTablaGeneral ref="exportar_dialog" type="liderazgos" />
        <CommonDialog ref="dialog_delete" @onClose="delete_item" />
    </div>
</template>

<script>
import { axiosHelper, messages } from '@/helpers'
import DashCard from '@/shared/components/DashCard'
import DialogExportarTablaGeneral from '@/dash/dialogs/DialogExportarTablaGeneral'
import BuscadorTabla from '@/shared/components/BuscadorTabla'
import LVRendimiento from '@/dash/components/LVRendimiento'
import CommonDialog from '@/shared/components/CommonDialog'
import raw_estados from '@/assets/estados'

export default {
    components: {
        DashCard,
        LVRendimiento,
        BuscadorTabla,
        DialogExportarTablaGeneral,
        CommonDialog
    },
    data: () => ({
        resumen: {},
        busqueda: "",
        items: null,
        estados: [
            { 
                columna: "estado_actividad", 
                actual: null, 
                lista: [{ value: null, text: 'Todos los estados' }, ...raw_estados['actividad']]
            },
            { 
                columna: "estado_tiempo", 
                icon: "av_timer", 
                actual: null, 
                lista: [{ value: null, text: 'Todos los tiempos' }, ...raw_estados['tiempo']] 
            }
        ],
        busqueda_settings: [
            { 
                value: null, 
                text: "Todo",
            },
            { 
                value: "cod_sapehs", 
                text: "Código SAP-EHS",
            },
            { 
                value: "fecha_ejecucion", 
                text: "Fecha de Ejecución",
            },
            { 
                value: "actividad", 
                text: "Actividad",
            },
            { 
                value: "coordinador",
                text: "Coordinador"
            },
            { 
                value: "area",
                text: "Área"
            }
        ],
        headers: [
            { text: "Ejecución", value: "fecha_ejecucion" },
            { text: "SAP-EHS", value: "cod_sapehs" },
            { text: "Actividad", value: "actividad" },
            { text: "Área", value: "area.nombre" },
            { text: "Coordinador", value: "coordinador_inicial" },
            { text: "Estado", value: "estado_actividad" },
            { text: "Tiempo", value: "estado_tiempo" },
            { text: "", value: "acciones" }
        ]
    }),
    computed: {
        tabla_loading() {
            return !this.items;
        },
        tabla_items() {
            if(!this.$refs.buscador) return this.items;
            return this.items.filter(x => this.$refs.buscador.filter_estado(x));
        }
    },
    methods: {
        filtro(value, search, item) {
            return this.$refs.buscador.filtro(value, search, item);
        },
        ask_eliminar(item) {
            this.$refs.dialog_delete.showDialog(messages.titles['question_delete_item'], messages.texts['delete_item'], "yes_no", item);
        },
        delete_item(response, item) {
            if(response == 'yes') {
                axiosHelper.del('liderazgos/' + item.id + '/')
                    .then(response => {
                        this.$parent.$parent.reloadComponent();
                    });
            }
        }
    },
    created() {
        this.$emit('title', { text: "Compromisos seguridad", icon: "inbox" });
    
        axiosHelper.get('liderazgos/')
            .then(response => this.items = response.data);
    }
}
</script>