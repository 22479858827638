<template>    
    <v-dialog v-model="visible" max-width="400" persistent transition="fade-transition">
        <WindowCard :title="title" actions-align="right">
            <span class="text--primary" v-html="text"></span>
            <template slot="actions">
                <v-btn depressed large color="primary" @click="closeDialog(item.type)"
                v-for="(item,i) in buttons" v-bind:key="i">
                    <span class="subtitle-2">{{ item.text }}</span>
                </v-btn>
            </template>
        </WindowCard>
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'

export default {
    components: {
        WindowCard
    },
    data: () => ({
        title: "",
        visible: false,
        text: "",
        target: null,
        buttonMode: "ok",
        buttons: [],
        modes: [
            [
                { text: "Aceptar", type: "ok" }
            ],
            [
                { text: "Si", type: "yes" },
                { text: "No", type: "no" } 
            ]
        ]
    }),
    methods: {
        getButtons(buttonMode) {
            if(this.buttonMode === "ok") {
                return this.modes[0];
            }

            if(this.buttonMode === "yes_no") {
                return this.modes[1];
            }
            return this.modes[0];
        },
        closeDialog(type) {
            this.visible = false;
            this.$emit('onClose', type, this.target);
        },
        showDialog(title, text, buttonMode, target) {
            this.title = title;
            this.text = text;
            this.buttonMode = buttonMode;
            this.buttons = this.getButtons(buttonMode);
            this.visible = true;
            this.target = target;
        }
    }
}
</script>