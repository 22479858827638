<template>
    <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on }">
            <v-text-field :disabled="disabled" :hide-details="hideDetails" outlined readonly solo :flat="flat" :dense="dense" prepend-inner-icon="event" :value="text" :rules="rules" :error-messages="errors" v-on="on"></v-text-field>
        </template>
        <v-date-picker no-title v-on:input="handleDate" :value="value" @change="menu = false"></v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment'

export default {
    props: ['value', 'rules', 'dense', 'errors', 'flat', 'hide-details', 'disabled'],
    data: () => ({
        menu: false
    }),
    methods: {
        handleDate(value) {
            this.$emit('input', value);
        },
        set(value) {
            this.handleDate(value);
        }
    },
    computed: 
    {
        text() {
        // Usar un placeholder si la fecha es nula
        // return this.value ? moment(this.value).format('L') : 'Selecciona una fecha';
        return this.value ? moment(this.value).format('L') : "";
        }
    },
    // mounted() {
    //     if(!this.value) {
    //         this.set(moment().format("YYYY-MM-DD"));
    //     }
    // }
}
</script>