<template>
    <v-row>
        <v-col cols="12" align="center">
            <h2 class="text--primary">{{ title }}</h2>
        </v-col>
        <v-col ref="col" cols="12" align="center">
            <v-progress-circular v-resize="on_resize" :value="value" :size="size" width="20" rotate="-90" :color="color">
                <h2>{{ Math.round(value * 10) / 10 }}%</h2>
            </v-progress-circular>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['value', 'title', 'color', 'max-size'],
    data: () => ({
        size: 200
    }),
    methods: {
        on_resize(e) {
            this.size = Math.min((this.maxSize || 200), this.$refs.col.clientWidth - 40);
        }
    }
}
</script>