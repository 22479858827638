<template>
    <v-row no-gutters justify="center" align="center">
        <v-col class="flex-shrink-0 flex-grow-1">
            <v-progress-linear :value="total_rel * 100" :height="24" :color="color" rounded striped />
        </v-col>
        <v-col class="pl-2 flex-shrink-1 flex-grow-0">
            <v-chip label dark :color="textColor">{{ total_rel | decimal_percent }}</v-chip>
        </v-col>
        <v-col class="pl-2 flex-shrink-1 flex-grow-0">
            <v-chip label dark :color="textColor">{{ value }} de {{ total }}</v-chip>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['value', 'total', 'color', 'text-color'],
    computed: {
        total_rel() {
            if(this.total < 1) return 0;
            return this.value / this.total;
        }
    }
}
</script>