<template>
    <div>
        <v-label v-if="estados">Buscar por estados</v-label>
        <v-row dense class="pb-4">
            <v-col cols="12" sm="4" md="3" v-for="(item, index) in estados" v-bind:key="index">
                <v-select dense solo outlined flat hide-details :prepend-inner-icon="item.icon || 'label'" v-model="item.actual" :items="item.lista" />
            </v-col>
        </v-row>
        <v-label>Buscar por columna</v-label>
        <v-row dense class="pb-4">
            <v-col cols="12" sm="4" md="3">
                <v-select return-object dense solo outlined flat hide-details prepend-inner-icon="filter_list" v-model="search_type" :items="types" item-text="text" />
            </v-col>
            <v-col cols="12" sm="8" md="9">
                <v-text-field dense solo outlined flat clearable hide-details prepend-inner-icon="search" label="Buscar..." :value="value" @input="handle_update" />
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    props: ['value', 'target', 'types', 'estados'],
    data: () => ({
        search_type: null
    }),
    methods: {
        handle_update(value) {
            this.$emit('input', value);
        },
        filtro(value, search, item) {
            if(!this.search_type || !this.search_type.value) {
                return Object.values(item).some(x => this.filter(x, search));
            }

            let target = item[this.search_type.value];
            return this.filter(target, search);
        },
        filter_estado(item) {
            let result = true;

            for (let e = 0; e < this.estados.length; e++) {
                const estado = this.estados[e];

                if(estado.actual == null) {
                    result = result && true;
                } else {
                    result = result && (item[estado.columna] === estado.actual);
                }
            }

            return result;
        },
        filter(target, search) {
            if(!target) 
                return false;

            let valid = false;

            if(typeof target === 'object' || typeof target === 'array') {
                let values = Object.values(target);
                valid = values.filter(x => typeof x === 'string').filter(x => x.toLowerCase().includes(search.toLowerCase())).length > 0;
            } else if(typeof target === 'string') {
                valid = target.toLowerCase().includes(search.toLowerCase());
            } else if(typeof target === 'number' || typeof target === 'bigint' || typeof target === 'boolean') {
                valid = target.toString().includes(search.toString());
            }

            return valid;
        }
    }
}
</script>