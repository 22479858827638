<template>
    <DashCard title="Resumen de Rendimiento">
        <v-alert type="error" v-show="error.visible">{{ error.text }}</v-alert>
        <v-row>
            <v-col cols="12" md="6">
                <v-label>Ver por Empleado</v-label>
                <v-select 
                    v-model="empleado"
                    :items="empleados"
                    hide-details
                    solo dense flat outlined clearable
                    label="Seleccione un empleado..." 
                    prepend-inner-icon="account_circle"
                    :item-text="(i) => i.first_name + ' ' + i.last_name"
                    item-value="id"
                    @change="show_results" />
            </v-col>
            <v-col cols="12" md="6">
                <v-label>Cantidad Total de Registros</v-label>
                <ProgressCounter v-model="total_persona" :total="total" color="primary" text-color="gray" />
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" sm="4" align="center">
                        <ProgressCircle title="% Invalidados" v-model="invalidados.rel" color="indigo" />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <ProgressCircle title="% Cerrados" v-model="cerrados.rel" color="success" />
                        <v-label>A tiempo</v-label>
                        <ProgressCounter v-model="cerrados.a_tiempo" :total="cerrados.total" color="green" />
                        <v-label>En Plazo</v-label>
                        <ProgressCounter v-model="cerrados.en_plazo" :total="cerrados.total" color="orange" />
                        <v-label>Atrasados</v-label>
                        <ProgressCounter v-model="cerrados.atrasado" :total="cerrados.total" color="red" />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <ProgressCircle title="% Activos" v-model="activos.rel" color="orange" />
                        <v-label>A tiempo</v-label>
                        <ProgressCounter v-model="activos.a_tiempo" :total="activos.total" color="green" />
                        <v-label>En Plazo</v-label>
                        <ProgressCounter v-model="activos.en_plazo" :total="activos.total" color="orange" />
                        <v-label>Atrasados</v-label>
                        <ProgressCounter v-model="activos.atrasado" :total="activos.total" color="red" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </DashCard>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import ProgressCircle from '@/dash/components/ProgressCircle'
import ProgressCounter from '@/dash/components/ProgressCounter'
import Message from '@/shared/components/Message'
import { axiosHelper, messages } from '@/helpers'

export default {
    components: {
        DashCard,
        ProgressCircle,
        ProgressCounter
    },
    data: () => ({
        stats: {},
        empleado: null,
        empleados: [],
        error: {
            visible: false,
            text: ""
        }
    }),
    computed: {
        total() {
            return this.stats.total || 0;
        },
        total_persona() {
            return this.stats.total_persona || 0;
        },
        total_rel() {
            return this.total_persona / this.total;
        },
        invalidados() {
            return this.stats.invalidos || {};
        },
        activos() {
            return this.stats.activos || {};
        },
        cerrados() {
            return this.stats.cerrados || {};
        }
    },
    mounted() {
        this.empleado = null;
        this.show_results();
    },
    methods: {
        update(data) {
            if(!!data.personas) {
                this.empleados = data.personas;
            }
            this.stats = data.stats;
        },
        show_error(data) {
            this.error.visible = true;
            this.error.text = data;
        },
        show_results() {
            this.error.visible = false;
            
            if(this.empleado) {
                axiosHelper.post('resumenes/lv/', { empleado: this.empleado })
                    .then(response => this.update(response.data))
                    .catch(error => this.show_error(error));
            } else {
                axiosHelper.get('resumenes/lv/')
                    .then(response => this.update(response.data))
                    .catch(error => this.show_error(error));
            }
        }
    }
}
</script>