<template>
    <v-overlay absolute color="white" v-show="value">
        <v-progress-circular indeterminate color="primary" />
    </v-overlay>
</template>

<script>
export default {
    props: ['value']
}
</script>