<template>
    <v-dialog v-model="visible" max-width="400" persistent transition="fade-transition">
        <WindowCard title="Descargando contenido" ref="main">
            <v-row class="py-0 ma-0" justify="center" align="center">
                <v-col class="flex-grow-0 flex-shrink-0">
                    <v-progress-circular indeterminate color="primary" size="64">
                        <v-icon>get_app</v-icon>
                    </v-progress-circular>
                </v-col>
                <v-col class="flex-grow-1 text-center">
                    <span class="text--primary subtitle-1" v-html="text"/>
                </v-col>
            </v-row>
        </WindowCard>
        <Message ref="message" @onClose="cancel()" />
    </v-dialog>
</template>

<script>
import { axiosHelper } from '@/helpers'
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import { mapState, mapActions } from 'vuex'
import download from 'downloadjs'
import { messages } from '@/helpers'

export default {
    components: {
        WindowCard,
        Message,
    },
    props: {
        externalSource: {
            type: Boolean,
            default: false
        },
        rawResponse: {
            type: Boolean,
            default: false
        },
        requestType: {
            type: String,
            default: 'post'
        }
    },
    data: () => ({
        destinated_filename: "archivo",
        text: "",
        visible : false,
        expired: false,
        api_url: null,
        api_payload: null
    }),
    computed: {
        ...mapState('auth', ['refresh_busy'])
    },
    methods: {
        ...mapActions('auth', ['login_refresh_nonupdate']),
        show(api_url, payload, filename) {
            if(!!filename) {
                this.destinated_filename = filename;
            }
            this.api_url = api_url;
            this.api_payload = payload;
            this.visible = true;
            this.text = "<b>Preparando descarga</b><br/>Por favor, espere...";
            this.try_download();
        },
        cancel() {
            this.visible = false;
            if(this.expired) {
                this.$router.push({ name: 'logout' });
            }
        },
        try_download() {
            if(this.requestType == 'post') {
                axiosHelper.post(this.api_url, this.api_payload)
                    .then(response => this.success(response))
                    .catch(error => this.check_error(error));
            } else if(this.requestType == 'get') {
            axiosHelper.get(this.api_url)
                .then(response => this.success(response))
                .catch(error => this.check_error(error));
            }
        },
        success(response) {
            this.$emit('on-download');
            if(this.rawResponse) {
                download(response.data, this.destinated_filename);
                this.visible = false;
                return;
            }

            if(this.externalSource) {
                window.open(response.data.url);
            } else {
                download(response.data.url);
            }
            this.visible = false;
        },
        check_error(error) {
            if(error.response.status == 401) {
                this.text = "<b>Actualizando sesión</b>";
                this.login_refresh_nonupdate();
            } else {
                this.show_error(error.response);
            }
        },
        show_error(error) {
            let text = "";
            if(error.status == 401) {
                text = messages.texts['error_session_expired'];
                this.expired = true;
            } else {
                text = messages.texts['error_download'];
            }
            this.$refs.message.show(messages.titles['error_generico'], text, "error")
        }
    },
    watch: {
        refresh_busy: function(_new, _old) {
            // el flag pasará de true a false. es ahí, cuando tenemos los tokens actualizados.
            if(_new == false && _old == true) {
                if(!this.api_url) return;
                this.text = "<b>Preparando descarga</b><br/>Por favor, espere...";
                axiosHelper.post(this.api_url, this.api_payload)
                    .then(response => this.success(response))
                    .catch(err => this.show_error(err.response));
            }
        }
    }
}
</script>