<template>
    <v-dialog v-model="visible" max-width="800" persistent transition="fade-transition">
        <WindowCard :title="title" actions-align="right">
            <v-label>
                El siguiente dialogo permite la descarga de todos los datos de este módulo dentro de un archivo Excel, 
                desde el primer dato insertado hasta el más reciente.<br/><br/>
            </v-label>
            <v-label>
                También puede exportar un periodo determinado de los datos seleccionando la opción inferior.
            </v-label>
            <v-form id="form" ref="download_form">
                <v-row>
                    <v-col cols="12">
                        <v-checkbox hide-details v-model="especifico" label="Exportar un periodo específico" />
                    </v-col>
                    <v-col cols="12" sm="6" v-if="especifico">
                        <v-label>Fecha de Inicio</v-label>
                        <DatePickerText dense v-model="inicio" :rules="[rules.required]" />
                    </v-col>
                    <v-col cols="12" sm="6" v-if="especifico">
                        <v-label>Fecha de Termino</v-label>
                        <DatePickerText dense v-model="termino" :rules="[rules.required]" />
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn text large color="primary" @click="close()">
                    <span class="subtitle-2">Cancelar</span>
                </v-btn>
                <v-btn large depressed color="primary" type="submit" form="download_form" @click="post_download">
                    <v-icon>save_alt</v-icon>
                    <span class="ml-2 subtitle-2">Descargar</span>
                </v-btn>
            </template>
        </WindowCard>
        <DownloadDialog ref="down_dialog" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import DownloadDialog from '@/shared/components/DownloadDialog'
import DatePickerText from '@/shared/components/DatePickerText'
import LoadingOverlay from '@/dash/components/LoadingOverlay'
import { axiosHelper, messages, valid_required } from '@/helpers'
import moment from 'moment'
import download from 'downloadjs'

export default {
    components: {
        DatePickerText,
        DownloadDialog,
        WindowCard,
        LoadingOverlay
    },
    props: ['type'],
    data: () => ({
        visible: false,
        especifico: false,
        inicio: moment().format('YYYY-MM-DD'),
        termino: moment().add(1, 'month').format('YYYY-MM-DD'),
        rules: {
            required: value => valid_required(value)
        }
    }),
    computed: {
        title() {
            var title = "Exportar tabla de datos para ";

            if(this.type == 'incidentes') {
                title += "Incidentes";
            }
            else if(this.type == 'permisos') {
                title += "Tareas Críticas";
            }
            else if(this.type == 'sias') {
                title += "Solicitudes de Intervención de Area";
            }
            else if(this.type == 'liderazgos') {
                title += "Compromisos seguridad";
            }
            else {
                title = "Exportar tabla de datos";
            }
            
            return title;
        }
    },
    methods: {
        show() {
            this.visible = true;
        },
        close() {
            this.visible = false;
        },
        post_download() {
            if(this.$refs.download_form) 
            {
                if(!this.$refs.download_form.validate()) 
                {
                    this.error();
                    return;
                }
            }

            var payload = this.especifico ? { fecha_inicio: this.inicio, fecha_termino: this.termino } : null;
            this.$refs.down_dialog.show('reportes/' + this.type + '/', payload);
        },
        error() {
            let text = messages.texts["error_check_fields"];
            this.$refs.dialog.show(messages.titles["error_generico"], text, "error");
        }
    }
}
</script>